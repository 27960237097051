/*! npm.im/scroll-restoration-polyfill */
'use strict';

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var once = _interopDefault(require(32));
var getScroll = require(22);

var state = 'auto';

function waitForScroll() {
	once(window, 'scroll', scrollTo.bind(window, getScroll.getScrollLeft(), getScroll.getScrollTop()));
}

if (!('scrollRestoration' in history)) {
	Object.defineProperty(history, 'scrollRestoration', {
		enumerable: true,
		get: function () { return state; },
		set: function (requestedState) {
			if (requestedState === state) {
				return;
			}
			if (requestedState === 'auto') {
				window.removeEventListener('popstate', waitForScroll);
				state = requestedState;
			} else if (requestedState === 'manual') {
				window.addEventListener('popstate', waitForScroll);
				state = requestedState;
			}
		}
	});
}